<template>
  <base-label
    :legend="placeholder ?? ''"
    :labelType="labelType"
    :errors="errors"
    :is-active="isOpen"
    :is-disabled="isDisabled"
    :is-loading="isLoading || isInternalLoading"
    :disable-disabled-opacity="disableDisabledOpacity"
    :feedback="feedback"
    :has-value="!!datetime"
    @click="open"
    @keydown.enter="open"
    @focus="onFocus"
    ref="label"
  >
    <span class="input-datetime__label" v-class-mod:input-datetime__label="{ isDisabled, isOpen }">
      <span v-if="!!datetime" class="input-datetime__text">
        <cut-text :text="datetime?.format?.(DateFormat.HH_MM_DD_MONTH_YYYY) || '-'" />
      </span>
      <span v-if="!datetime" class="input-datetime__not-set">
        <cut-text :text="fallbackLabel || translations.datetime.notSpecified" />
      </span>
      <icon-toggle-button
        v-if="canClear && !isDisabled && !!datetime"
        :tooltip="{ title: translations.inputs.clear }"
        :action="
          (e) => {
            e.stopPropagation();
            close();
            updateValue(undefined);
          }
        "
        icon="close"
        button-size="medium"
        tabindex="0"
      />
    </span>
    <template v-if="!isDisabled && label?.$el">
      <icon-toggle-button :action="open" button-size="medium" icon="today" />

      <AppTeleport v-if="isOpen">
        <datetime-picker
          :datetime="datetime"
          :min="min"
          :max="max"
          :title="title"
          :tabindex="-1"
          class="input-datetime__picker"
          @update="(d: DateTime) => updateValue(d)"
          @close="close"
          :ref="
            (r) => {
              refHandler(r, (elm) => (dateTimePickerEl = elm));
            }
          "
          v-lock-focus="true"
          v-stick-to="{
            align: 'right',
            stick: 'left',
            placement: 'bottom',
            stickTo: label,
            element: dateTimePickerEl,
            minHeight: 420,
          }"
          v-click-outside="{
            callback: () => close(),
          }"
        />
      </AppTeleport>
    </template>
  </base-label>
</template>

<script lang="ts" setup>
import { nextTick, ref, watch } from "vue";

import { AppTeleport } from "@horizon56/bootstrap";
import { vClickOutside } from "@horizon56/directives/click-outside";
import { vLockFocus } from "@horizon56/directives/lock-focus";
import { vStickTo } from "@horizon56/directives/stick-to";
import { DateFormat, DateTime } from "@horizon56/time";
import { delayFn, refHandler } from "@horizon56/utils";

import { translations } from "@/infrastructure/translations";

import IconToggleButton from "@/components/buttons/icon-toggle-button.vue";
import CutText from "@/components/content/cut-text.vue";
import DatetimePicker from "@/components/date-and-time/datetime-picker/datetime-picker.vue";
import BaseLabel, { PublicProps } from "@/components/inputs/base-label.vue";
import { FeedbackReturnType } from "@/components/inputs/input-feedback.vue";

const props = withDefaults(
  defineProps<
    PublicProps & {
      placeholder?: string;
      datetime?: DateTime;
      max?: DateTime;
      min?: DateTime;
      title?: string;
      canClear?: boolean;
      fallbackLabel?: string;
      activateOnFocus?: boolean;
      update?: (d?: DateTime) => FeedbackReturnType;
    }
  >(),
  {
    activateOnFocus: true,
  },
);

const emit = defineEmits<{
  (e: "isFocus", a: boolean): void;
  (e: "update", a?: DateTime): void;
}>();

const isOpen = ref(false);
const isFocus = ref(false);
const lockFocus = ref(false);

const isInternalLoading = ref(false);
const feedback = ref<PublicProps["feedback"]>();
const label = ref<InstanceType<typeof BaseLabel>>();
const dateTimePickerEl = ref();

const onFocus = async () => {
  if (props.activateOnFocus && !isOpen.value) {
    await open();
  }
};

const updateValue = async (u?: DateTime) => {
  emit("update", u);
  if (typeof props.update === "function") {
    isInternalLoading.value = true;
    const result = await props.update(u);
    if (result && "status" in result) {
      feedback.value = {
        ...result,
        callback: () => {
          feedback.value = undefined;
          result.callback?.();
        },
      };
    }
    isInternalLoading.value = false;
  }
};

watch(
  () => isFocus.value || isOpen.value,
  (is) => emit("isFocus", is),
  { immediate: true },
);

const open = async () => {
  if (!props.isDisabled) {
    window.requestAnimationFrame(async () => {
      isOpen.value = true;
      if (isOpen.value) {
        lockFocus.value = true;
        await delayFn(() => !!dateTimePickerEl.value);
        dateTimePickerEl.value?.focus();
      }
    });
  }
};

const close = async () => {
  lockFocus.value = false;
  await nextTick();
  if (shouldFocusOnLabel()) {
    label.value?.$el.focus();
  }
  isOpen.value = false;
};

const shouldFocusOnLabel = () =>
  dateTimePickerEl.value?.contains(document.activeElement) || !document.contains(document.activeElement);
</script>

<style lang="scss" scoped>
.input-datetime {
  &__label {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    flex-flow: row nowrap;
    user-select: none;
    outline: none;
    margin-right: auto;
  }
  &__not-set {
    color: var(--black-50);
  }
  &__not-set,
  &__text {
    margin-right: auto;
    margin-right: auto;
  }
  &__clear {
    margin-left: 10px;
  }
  &__picker:focus {
    outline: none;
  }
}
</style>
